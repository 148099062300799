import React from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, NavLink } from 'react-router-dom'
import { AccountCircle, Announcement, CalendarMonthOutlined, Campaign, Chat, Dvr, FollowTheSigns, Home,  Label,  ListAlt,  ManageAccounts, MoreVert, Notifications, PhoneAndroid, Settings, Store, Tablet, TransferWithinAStation, Tv, WorkOutlined} from '@mui/icons-material';

function AdminHeader() {

    const {authUser } = useAuth();

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top shadow mb-3">
            <div className="container-fluid">
                <Link className="me-5 navbar-brand" to="/">
                    <img src='/images/moe-logo.jpg' style={{height:40}} />
                    <span className='ms-3 text-dark ' >Ministry of Environment</span>
                    
                </Link> {/* navbar-brand  */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"18px"}}>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Organizations</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Manage Organization</a></li>
                                <li><a className="dropdown-item" href="#">Organization Users</a></li>
                                <li><a className="dropdown-item" href="#">Organization Masters</a></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Projects</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Project List</a></li>
                                <li><a className="dropdown-item" href="#">Pending List</a></li>
                                <li><a className="dropdown-item" href="#">Complete</a></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Budgets</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Budget Request List</a></li>
                                <li><a className="dropdown-item" href="#">Approved Request</a></li>
                                <li><a className="dropdown-item" href="#">Pennding Request</a></li>
                                <li><a className="dropdown-item" href="#">Rejected</a></li>
                                <li><a className="dropdown-item" href="#">Reports</a></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Communications</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Message</a></li>
                                <li><a className="dropdown-item" href="#">Circular</a></li>
                                <li><a className="dropdown-item" href="#">News</a></li>
                            </ul>
                        </li>
                        
                        {/*
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Dropdown</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Link</a></li>
                                <li><a className="dropdown-item" href="#">Another link</a></li>
                                <li><a className="dropdown-item" href="#">A third link</a></li>
                            </ul>
                        </li>
                        */}
                    </ul>

                        <ul className="navbar-nav">
                            { authUser.role <= 2 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVert/> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    <li><NavLink className="dropdown-item" to="/master/list"><Label /> Status Master</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/business/details"><WorkOutlined/> Business Details</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/list"><ManageAccounts/> Manage User Accounts</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/role"><Settings/> Manage User Role</NavLink></li>
                                </ul>
                            </li>
                            }



                            <li className="nav-item dropdown">
                                <a className="btn btn-danger" style={{borderRadius:"50px"}} href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                    {authUser.firstName.charAt(0)}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                    {/*<li><a className="dropdown-item" href="/auth/profile">My Profile</a></li> */ }
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                </div>
            </div>
        </nav>
    )
}

export default AdminHeader