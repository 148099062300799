import React,{useState,useContext, useEffect} from 'react'
import io from 'socket.io-client'
const SocketContext = React.createContext();

export const useSocket = () => {
    return useContext(SocketContext);
}




export const SocketProvider = (props) =>{

    const [appName,setAppName] = useState(null);
    const [socket,setSocket] =  useState(null)

    const getAppNameOld = () =>{
        let _appName =  "local"//process.env.REACT_APP_SOCKET_NAME;
        const api_url = localStorage.getItem("portalUrl");
        let a = document.createElement('a');
        a.href = api_url;
        _appName = a.hostname.replace("-api.moe-mis.in","");
        _appName = "moe-mis_"+_appName;
        setAppName(_appName);
        let _socket = io.connect("https://tms-notification.gtoken.in?app="+_appName);
        setSocket(_socket);
    }

    const getAppName = () =>{
        let _appName =  "local-moe-mis"//process.env.REACT_APP_SOCKET_NAME;
        setAppName(_appName);
        let _socket = io.connect("https://tms-notification.gtoken.in?app="+_appName);
        setSocket(_socket);
    }

    useEffect(()=>{
        getAppName()
    },[]);
    
    const value= {
        appName,
        socket,
    }

    return (
        <SocketContext.Provider value= {value}>
            {props.children}
        </SocketContext.Provider>
    )
}