import api from "../../../api/api";

const getDetails = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/business").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/business",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateInline = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/business",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const businessServices = {
    getDetails,
    updateInline,
    save,
}
export default businessServices;