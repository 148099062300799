import React from 'react'
import { Route, Routes } from 'react-router'
import LoginPage from '../app/auth/pages/LoginPage'
import SelectPortalPage from '../app/auth/pages/SelectPortalPage'


function AuthRoutes() {
  return (
    <Routes>
        <Route path="/" element={<LoginPage/>}></Route>
        <Route path="/auth/login" element={<LoginPage/>}></Route>
        <Route path="/auth/select-portal" element={<SelectPortalPage/>}></Route>
    </Routes>
  )
}

export default AuthRoutes