import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";


const setupLocalStorage = (payload) =>{
    localStorage.setItem('authUser',JSON.stringify(payload));
}

const attemptLogin = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("auth/login",payload).then( (res)=>{
            if(!res.data.error){
                setupLocalStorage(res.data.data)
                resolve(res.data);
            } else {
                reject({
                    message: res.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const authServices = {
    attemptLogin,
}

export default authServices;