import React, { useState } from 'react'
import { AlertProvider } from './mis/context/AlertContext';
import { useAuth } from './mis/context/AuthContext'

import AdminRoutes from './mis/routes/AdminRoutes'
import AuthRoutes from './mis/routes/AuthRoutes'

function App() {
    const {isLoggedIn} = useAuth();
    return (
        <>
        <AlertProvider>
            {isLoggedIn ? 
                <AdminRoutes/>
                :
                <AuthRoutes/>
            }
        </AlertProvider>
        </>
    )
}
export default App