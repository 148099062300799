import React from 'react'
import { Route, Routes } from 'react-router'

import AdminDashboardPage from '../app/admin/dashboard/pages/AdminDashboardPage'
import LogoutPage from '../app/auth/pages/LogoutPage'
import SystemMasterListPage from '../app/master/pages/SystemMasterListPage'

import UserListPage from '../app/admin/user/pages/UserListPage'
import UserDetailsPage from '../app/admin/user/pages/UserDetailsPage'
import UserRoleListPage from '../app/admin/user/pages/UserRoleListPage'
import BusinessDetails from '../app/admin/business/pages/BusinessDetails'


function AppRoutes() {
  return (
    <Routes>
        {/* Admin */}
        <Route path="/" element={<AdminDashboardPage/>}></Route>
        <Route path="/business/details" element={<BusinessDetails />}></Route>
        
        
        <Route path="/user/list" element={<UserListPage /> }></Route>
        <Route path="/user/details/:id" element={<UserDetailsPage /> }></Route>
        <Route path="/user/role" element={<UserRoleListPage /> }></Route>

        <Route path="/master/list" element={ <SystemMasterListPage /> } />
        <Route path="/auth/logout" element={<LogoutPage/>}></Route>

        
        

    </Routes>
  )
}

export default AppRoutes