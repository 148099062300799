import React from 'react'
import AppLayout from '../../../../layout/components/AppLayout'

function AdminDashboardPage() {
    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-2'>
                        <div className='card bg-primary text-white p-3'>
                            <p>Organizations</p>
                            <div className='text-center'>
                                <h3>426</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className='card bg-warning p-3'>
                            <p>Project In Progress</p>
                            <div className='text-center'>
                                <h3>221</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className='card bg-success text-white p-3'>
                            <p>Project Complete</p>
                            <div className='text-center'>
                                <h3>683</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className='card bg-danger text-white p-3'>
                            <p>Project Pending</p>
                            <div className='text-center'>
                                <h3>25</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className='card text-danger border-danger p-3'>
                            <p>Buduget Request</p>
                            <div className='text-center'>
                                <h3>634500</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className='card text-success border-success p-3'>
                            <p>Request In Progress</p>
                            <div className='text-center'>
                                <h3>634500</h3>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <div className='card'>
                            <div className='card-header'>Recent Budget Request</div>
                            <div className='card-body'>
                                <table className='table table-bordered table-striped'>
                                    <thead class="table-light">
                                        <tr>
                                            <td>Organization</td>
                                            <td>Department</td>
                                            <td>Project</td>
                                            <td>City</td>
                                            <td>Level</td>
                                            <td>Request Ammount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Abc</td>
                                            <td>Department 1</td>
                                            <td>Electronic Update</td>
                                            <td>New Delhi</td>
                                            <td>Natinal</td>
                                            <td>364000</td>
                                        </tr>
                                        <tr>
                                            <td>Xyz</td>
                                            <td>Forest</td>
                                            <td>Computer Purchase</td>
                                            <td>Kolkata</td>
                                            <td>City</td>
                                            <td>93000</td>
                                        </tr>
                                        <tr>
                                            <td>Xyz</td>
                                            <td>Costal</td>
                                            <td>Device Install</td>
                                            <td>Mumbai</td>
                                            <td>City</td>
                                            <td>192000</td>
                                        </tr>
                                        <tr>
                                            <td>Xyz</td>
                                            <td>Costal</td>
                                            <td>Construction</td>
                                            <td>Goa</td>
                                            <td>State</td>
                                            <td>433300</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='card'>
                            <div className='card-header'>Recent Approval</div>
                            <div className='card-body'>
                            <table className='table table-bordered table-striped'>
                                    <thead class="table-light">
                                        <tr>
                                            <td>Organization</td>
                                            <td>Department</td>
                                            <td>Project</td>
                                            <td>City</td>
                                            <td>Level</td>
                                            <td>Request Ammount</td>
                                            <td>Approved Ammount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Abc</td>
                                            <td>Department 1</td>
                                            <td>Electronic Update</td>
                                            <td>New Delhi</td>
                                            <td>Natinal</td>
                                            <td>364000</td>
                                            <td>314000</td>
                                        </tr>
                                        <tr>
                                            <td>Xyz</td>
                                            <td>Forest</td>
                                            <td>Computer Purchase</td>
                                            <td>Kolkata</td>
                                            <td>City</td>
                                            <td>93000</td>
                                            <td>82500</td>
                                        </tr>
                                        <tr>
                                            <td>Xyz</td>
                                            <td>Costal</td>
                                            <td>Device Install</td>
                                            <td>Mumbai</td>
                                            <td>City</td>
                                            <td>192000</td>
                                            <td>168750</td>
                                        </tr>
                                        <tr>
                                            <td>Xyz</td>
                                            <td>Costal</td>
                                            <td>Construction</td>
                                            <td>Goa</td>
                                            <td>State</td>
                                            <td>433300</td>
                                            <td>282500</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default AdminDashboardPage